import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { API_BASE_URL } from "src/constants/api";
import { useParams } from "react-router-dom";

const UpdateBlog = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState({
    title_en: "",
    title_ar: "",
    meta_title_en: "",
    meta_title_ar: "",
    description_en: EditorState.createEmpty(),
    description_ar: EditorState.createEmpty(),
    short_description_en: "",
    short_description_ar: "",
    meta_description_en: "",
    meta_description_ar: "",
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlog({
      ...blog,
      [name]: value,
    });
  };
  const handleEditorChange = (field, editorState) => {
    setBlog({
      ...blog,
      [field]: editorState,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      Object.keys(blog).forEach((key) => {
        if (key !== "id" && key !== "created_at") {
          // Skip 'id' and 'createTime'
          const value = blog[key];
          if (value instanceof EditorState) {
            // Convert EditorState to HTML string
            const htmlString = stateToHTML(value.getCurrentContent());
            formData.append(key, htmlString);
          } else {
            formData.append(key, value);
          }
        }
      });

      const response = await axios.put(
        `https://ecofuture-backend.geek-house.dev/en/blog/update/${blogId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      setLoading(false);
      setSnackbarMessage("Blog successfully updated!"); // Changed the message to 'updated'
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Error updating blog");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const isRtl = (field) => {
    return field.endsWith("_ar") ? "rtl" : "ltr";
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/blog/${blogId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        });

        const updatedBlogData = { ...response.data[0] };

        const contentStateFromHtmlEn = stateFromHTML(
          updatedBlogData.description_en
        );
        const contentStateFromHtmlAr = stateFromHTML(
          updatedBlogData.description_ar
        );
        updatedBlogData.description_en = EditorState.createWithContent(
          contentStateFromHtmlEn
        );
        updatedBlogData.description_ar = EditorState.createWithContent(
          contentStateFromHtmlAr
        );

        setBlog((prevBlog) => ({
          ...prevBlog,
          ...updatedBlogData,
        }));
      } catch (error) {
        console.error("There was an error fetching the blog:", error);
      }
    };

    fetchBlog();
  }, []);

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5">Update Blog</Typography>
          <form onSubmit={handleSubmit}>
            {Object.keys(blog).map((field, index) =>
              field === "description_en" || field === "description_ar" ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "16px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="body1">
                    {`${field.split("_").join(" ")} (${
                      field.endsWith("_ar") ? "AR" : "EN"
                    })`}
                  </Typography>
                  <Editor
                    editorState={blog[field]}
                    onEditorStateChange={(editorState) =>
                      handleEditorChange(field, editorState)
                    }
                  />
                </Box>
              ) : (
                <TextField
                  key={index}
                  label={`${field.split("_").join(" ")} (${
                    field.endsWith("_ar") ? "AR" : "EN"
                  })`}
                  name={field}
                  fullWidth
                  dir={isRtl(field)}
                  onChange={handleChange}
                  margin="normal"
                  value={blog[field]}
                />
              )
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              style={{ marginTop: "16px" }}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UpdateBlog;
