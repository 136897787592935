import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
  Typography,
  Stack,
  TextField,
  Box,
  Modal,
  FormControl,
} from "@mui/material";
import { API_BASE_URL } from "src/constants/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CategoriesTable = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({
    title_en: "",
    title_ar: "",
  });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleOpen = (id) => {
    setOpen(true);
    if (id) {
      handleEdit(id);
    }
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setIsOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleClose = () => {
    setOpen(false);
    setNewCategory({
      title_en: "",
      title_ar: "",
    });
  };

  const handleChange = (event) => {
    setNewCategory({
      ...newCategory,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title_en", newCategory.title_en);
    formData.append("title_ar", newCategory.title_ar);

    const authToken = localStorage.getItem("auth_token");

    try {
      const response = await fetch(
        "https://ecofuture-backend.geek-house.dev/en/productcategory/add",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      handleClose();
      fetchCategories();
    } catch (error) {
      console.error("Adding category failed: ", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://ecofuture-backend.geek-house.dev/en/productcategories"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
      console.log(data);
    } catch (error) {
      console.error("Fetching categories failed: ", error);
    }
  };

  const handleEdit = (categoryId) => {
    const category = categories.find((category) => category.id === categoryId);
    setNewCategory({
      ...newCategory,
      title_en: category?.title_en,
      title_ar: category?.title_ar,
    });
  };

  const handleDelete = async (categoryId) => {
    const authToken = localStorage.getItem("auth_token");

    try {
      const response = await fetch(
        `${API_BASE_URL}/productcategory/delete/${categoryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      fetchCategories();
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Deleting category failed: ", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Categories
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap-reverse"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: 5 }}
      >
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button onClick={handleOpen}>Add Category</Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="categories table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Name Arabic</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow
                key={category.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {category.id}
                </TableCell>
                <TableCell>{category.title_en}</TableCell>
                <TableCell>{category.title_ar}</TableCell>

                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => handleOpen(category.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => handleOpenDeleteModal(category.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Category
          </Typography>
          <FormControl fullWidth component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title_en"
              label="English Title"
              name="title_en"
              autoComplete="title_en"
              autoFocus
              value={newCategory.title_en}
              onChange={handleChange}
            />{" "}
            <TextField
              margin="normal"
              required
              fullWidth
              name="title_ar"
              label="Arabic Title"
              type="text"
              id="title_ar"
              autoComplete="title_ar"
              value={newCategory.title_ar}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Category
            </Button>
          </FormControl>
        </Box>
      </Modal>

      {/* delete confirm modal */}
      <Modal
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this category?
          </Typography>
          <FormControl fullWidth component="form">
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => handleDelete(deleteId)}
            >
              Delete
            </Button>
            <Button
              onClick={handleCloseDeleteModal}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </FormControl>
        </Box>
      </Modal>
    </Container>
  );
};

export default CategoriesTable;
