import { Navigate, useRoutes, Outlet } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import BlogPage from "./pages/blogs/BlogPage";
import UserPage from "./pages/UserPage.jsx";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/products";
import DashboardAppPage from "./pages/DashboardAppPage";
import AddProductPage from "./pages/products/add";
import CompanyProfilePage from "./pages/about";
import ContactInfoPage from "./pages/contact";
import ServicesPage from "./pages/services";
import ServiceDetailPage from "./pages/services/service-details";
import UpdateProductPage from "./pages/products/update";
import AddServicePage from "./pages/services/add";
import AddBlog from "./pages/blogs/add";
import UpdateBlog from "./pages/blogs/update";
import CategoriesTable from "./pages/category";

export function AuthenticatedRoute() {
  const authToken = localStorage.getItem("auth_token");

  const isAuthenticated =
    authToken !== null && authToken !== undefined && authToken !== "";

  return isAuthenticated ? <DashboardLayout /> : <Navigate to="/" replace />;
}

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // Main page route
    {
      path: "/",
      element: <LoginPage />,
      index: true,
    },
    {
      path: "/dashboard",
      element: <AuthenticatedRoute />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "user", element: <UserPage /> },
        { path: "products/add", element: <AddProductPage /> },
        { path: "products/:productId", element: <UpdateProductPage /> },
        { path: "products", element: <ProductsPage /> },
        { path: "about", element: <CompanyProfilePage /> },

        {
          path: "services",
          element: <ServicesPage />,
        },

        { path: "services/:serviceId", element: <ServiceDetailPage /> },
        { path: "services/add", element: <AddServicePage /> },

        { path: "contact", element: <ContactInfoPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "category", element: <CategoriesTable /> },

        { path: "blogs/add", element: <AddBlog /> },
        { path: "blogs/:blogId", element: <UpdateBlog /> },
      ],
    },

    {
      path: "/login",
      element: <Navigate to="/" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
