import PropTypes from "prop-types";
// @mui
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import ShopProductCard from "./ProductCard";
import { API_BASE_URL } from "src/constants/api";
import axios from "axios";
import { useState } from "react";

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, fetchProducts, ...other }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const handleOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteProduct = async () => {
    const response = await axios.delete(
      `${API_BASE_URL}/product/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
    fetchProducts();
    console.log(response);
  };

  const handleDelete = () => {
    deleteProduct(id);
    setOpen(false);
  };
  return (
    <Grid container spacing={3} {...other}>
      {products?.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={6}>
          <ShopProductCard product={product} deleteProduct={handleOpen} />
        </Grid>
      ))}

      {/* modal confirm delete */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this product?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
