import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { API_BASE_URL } from "src/constants/api";
import axios from "axios";
import { redirect } from "react-router-dom";
import Iconify from "src/components/iconify";

const AddProductPage = () => {
  const [product, setProduct] = useState({
    title_ar: "",
    title_en: "",
    description_en: "",
    description_ar: "",
    photos: [],
    category: "",
    tags: [],
  });
  const [featuredImageId, setFeaturedImageId] = useState(null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inStock, setInStock] = useState(false);
  const [images, setImages] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [categories, setCategories] = useState([]);

  const TAGS_DATA = [
    { id: "home", name: "Home", icon: "iconamoon:home" },
    { id: "business", name: "Business", icon: "ion:business" },
    { id: "solar", name: "Solar", icon: "fa6-solid:solar-panel" },
    { id: "battery", name: "Battery", icon: "majesticons:battery" },
    // ... Add other tags with respective icons
  ];

  const handleChange = (event) => {
    const name = event.target.name;
    setProduct({
      ...product,
      [name]: event.target.value,
    });
  };

  const Tag = ({ tag, isSelected, onTagClick }) => (
    <Card
      variant="outlined"
      align="center"
      style={{
        cursor: "pointer",
        backgroundColor: isSelected ? "#089537" : "white",
      }}
      onClick={() => onTagClick(tag.id)}
    >
      <CardContent>
        <Icon icon={tag.icon} color={isSelected ? "white" : "black"} />
        <Typography color={isSelected ? "white" : "black"}>
          {tag.name}
        </Typography>
      </CardContent>
    </Card>
  );

  const handleTagClick = (tagId) => {
    setProduct((prev) => ({
      ...prev,
      tags: prev.tags.includes(tagId)
        ? prev.tags.filter((t) => t !== tagId)
        : [...prev.tags, tagId],
    }));
  };

  const handlePdfFileChange = (event) => {
    const selectedPdfFile = event.target.files[0];
    setPdfFile(selectedPdfFile);

    // Create an object URL for the PDF
    const pdfURL = URL.createObjectURL(selectedPdfFile);
    setPdfUrl(pdfURL);
  };

  useEffect(() => {
    console.log("Product Photos:", product.photos);
  }, [product.photos]);

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files).map((file) => ({
      file,
      id: Math.random().toString(), 
    }));

    console.log("New Images:", newImages); 

    setProduct((prev) => ({
      ...prev,
      photos: [...prev.photos, ...newImages],
    }));
    console.log("Product Photos:", product.photos);
    
    if (!featuredImageId && newImages.length) {
      setFeaturedImageId(newImages[0].id);
    }
  };

  const clearPdf = () => {
    // Clear the PDF from state and revoke the object URL
    setPdfFile(null);
    URL.revokeObjectURL(pdfUrl);
    setPdfUrl(null);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://ecofuture-backend.geek-house.dev/en/productcategories"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
      console.log(data);
    } catch (error) {
      console.error("Fetching categories failed: ", error);
    }
  };

  const handleDeleteImage = (id) => {
    setProduct((prev) => ({
      ...prev,
      photos: prev.photos.filter((photo) => photo.id !== id),
    }));
  };
  const handslestatusChange = (event) => {
    console.log("status", event.target.checked);
    setStatus(event.target.checked);
  };
  const handleInStockChange = (event) => {
    console.log("inStock", event.target.checked);
    setInStock(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    // Map your state to API expected fields
    formData.append("category_id", product.category);
    formData.append("title_en", product.title_en);
    formData.append("title_ar", product.title_ar);
    formData.append("description_en", product.description_en);
    formData.append("description_ar", product.description_ar);
    formData.append("instock", inStock ? 1 : 0); // assuming instock is a boolean
    formData.append("status", status ? "Special Price" : ""); // assuming status is a string

    // Handle tags (boolean flags)
    TAGS_DATA.forEach((tag) => {
      formData.append(tag.id, product.tags.includes(tag.id) ? 1:0);
    });

    // Handle images
    product.photos.forEach((imageObj, index) => {
      formData.append("photos", imageObj.file);
    });

    if (pdfFile) {
      formData.append("pdf", pdfFile);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/product/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            accept: "multipart/form-data",
          },
        }
      );

      console.log("Success:", response);
      // Handle success clear state
      setProduct({
        arabicName: "",
        name: "",
        description: "",
        arabicDescription: "",
        photos: [],
        category: "",
        status: [],
        inStock: false,
        tags: [],
      });
      setFeaturedImageId(null);
      setImages([]);
      // Redirect to products page
      redirect("/dashboard/products");
    } catch (error) {
      console.log("Error:", error);

      // Handle errors
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      minHeight="100vh"
      bgcolor="background.default"
      width={"100%"}
    >
      <Container component="main" maxWidth="xl">
        <Card>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Add Product
            </Typography>
            <form onSubmit={handleSubmit} >
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Product Name"
                  name="title_en"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Arabic Product Name"
                  name="title_ar"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    height: "120px",
                    border: "2px dotted #ccc",
                    borderRadius: "4px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography>Click to select files</Typography>
                  </Box>
                </label>
              </Box>
              <Box mb={2}>
                <Grid container spacing={3}>
                  {product?.photos?.map((imageObj) => (
                    <Grid item xs={4} key={imageObj.id}>
                      <img
                        src={URL.createObjectURL(imageObj.file)}
                        alt="uploaded preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                      {featuredImageId === imageObj.id && (
                        <Icon
                          icon="fa-solid:star"
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                          }}
                        />
                      )}
                      <IconButton
                        edge="end"
                        onClick={() => handleDeleteImage(imageObj.id)}
                      >
                        <Icon icon="typcn:delete" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => setFeaturedImageId(imageObj.id)}
                      >
                        <Icon
                          icon="fa-solid:star"
                          color={
                            featuredImageId === imageObj.id ? "gold" : "gray"
                          }
                        />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="category"
                    value={product.category}
                    onChange={handleChange}
                    label="Category"
                    required
                  >
                    {/* Populate this with your categories */}
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.title_en}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={status}
                        checked={status}
                        onChange={handslestatusChange}
                        name="status"
                      />
                    }
                    label="Special Price"
                  />
                </FormGroup>
              </Box>
              <Box mb={2}>
                <FormGroup>
                  <FormControlLabel
                    sx={{ fontSize: "1.5rem" }}
                    control={
                      <Checkbox
                        checked={inStock}
                        value={inStock}
                        onChange={handleInStockChange}
                        name="inStock"
                      />
                    }
                    label="In Stock"
                  />
                </FormGroup>
              </Box>
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={4}
                  label="Product Description"
                  name="description_en"
                  onBlur={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={4}
                  label="Arabic Product Description"
                  name="description_ar"
                  onBlur={handleChange}
                />
              </Box>
              <Box mb={2} display="flex" justifyContent="start" gap={4}>
                {TAGS_DATA.map((tag) => (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    isSelected={product.tags.includes(tag.id)}
                    onTagClick={handleTagClick}
                  />
                ))}
              </Box>

              <Box mb={2}>
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    height: "50px",
                    border: "2px dotted #ccc",
                    borderRadius: "4px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <input
                    accept="application/pdf"
                    type="file"
                    onChange={handlePdfFileChange}
                    style={{ display: "none" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography>Click to select a PDF file</Typography>
                  </Box>
                </label>
              </Box>

              {pdfFile && (
                <Typography
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  <Iconify
                    icon={"teenyicons:pdf-solid"}
                    sx={{ color: "red", width: "40px", height: "100%" }}
                  />
                  <Typography>{pdfFile.name}</Typography>
                  <IconButton onClick={() => setPdfFile(null)}>
                    <Iconify icon={"eva:trash-2-outline"} />
                  </IconButton>
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default AddProductPage;
