import axios from "axios";
import React, { useState } from "react";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddBlog = () => {
  const [blog, setBlog] = useState({
    title_en: "",
    title_ar: "",
    meta_title_en: "",
    meta_title_ar: "",
    description_en: "",
    description_ar: "",
    short_description_en: "",
    short_description_ar: "",
    meta_description_en: "",
    meta_description_ar: "",
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlog({
      ...blog,
      [name]: value,
    });
  };
  const handleEditorChange = (field, editorState) => {
    setBlog({
      ...blog,
      [field]: editorState,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      Object.keys(blog).forEach((key) => {
        formData.append(key, blog[key]);
      });

      const response = await axios.post(
        "https://ecofuture-backend.geek-house.dev/en/blog/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      setLoading(false);
      setSnackbarMessage("Blog successfully added!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Error adding blog");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  const isRtl = (field) => {
    return field.endsWith("_ar") ? "rtl" : "ltr";
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5">Add New Blog</Typography>
          <form onSubmit={handleSubmit}>
            {Object.keys(blog).map((field, index) =>
              field === "description_en" || field === "description_ar" ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "16px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="body1">
                    {`${field.split("_").join(" ")} (${
                      field.endsWith("_ar") ? "AR" : "EN"
                    })`}
                  </Typography>
                  <Editor
                    editorState={blog[field]}
                    onEditorStateChange={(editorState) =>
                      handleEditorChange(field, editorState)
                    }
                  />
                </Box>
              ) : (
                <TextField
                  key={index}
                  label={`${field.split("_").join(" ")} (${
                    field.endsWith("_ar") ? "AR" : "EN"
                  })`}
                  name={field}
                  fullWidth
                  dir={isRtl(field)}
                  onChange={handleChange}
                  margin="normal"
                />
              )
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              style={{ marginTop: "16px" }}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddBlog;
