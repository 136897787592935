import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
// @mui
import {
  Container,
  Stack,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
// components
import { ProductList } from "../../sections/@dashboard/products";
// mock
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "src/constants/api";
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/products`);
      setProducts(response.data.products);
      console.log(response);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch products: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Helmet>
        <title> Eco-Future: Products </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <RouterLink
              to="/dashboard/products/add"
              color="primary"
              sx={{ mr: "5em" }}
            >
              <Button variant="contained">Add Product</Button>
            </RouterLink>
          </Stack>
        </Stack>
        {loading ? (
          <CircularProgress size={60} />
        ) : (
          <ProductList products={products} fetchProducts={fetchProducts} />
        )}{" "}
      </Container>
    </>
  );
}
