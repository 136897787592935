import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";

const CompanyProfilePage = () => {
  const [profile, setProfile] = useState({
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    feature1_en: "",
    feature1_ar: "",
    feature2_en: "",
    feature2_ar: "",
    hintEn: "",
    hintAr: "",
    vision_en: "",
    vision_ar: "",
    vision_photo: null,
    mission_en: "",
    mission_ar: "",
    mission_photo: null,
    team: [],
  });
  const [open, setOpen] = useState(false);
  const [team, setTeam] = useState([
    {
      full_name: "",
      job_title: "",
      photo: null,
    },
  ]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e, section) => {
    const file = e.target.files[0];

    setProfile((prev) => {
      if (section.startsWith("team")) {
        const idx = parseInt(section.match(/\d+/)[0], 10); // get the index from the section string
        const newTeam = [...prev.team];
        newTeam[idx].image = file;
        return { ...prev, team: newTeam };
      } else {
        return { ...prev, [section]: file };
      }
    });
  };

  const fetchTeam = async () => {
    try {
      const response = await axios.get(
        "https://ecofuture-backend.geek-house.dev/en/teammembers"
      );
      const data = response.data;
      console.log(response);
      setTeam(data);
    } catch (error) {
      console.error("There was an error fetching the data:", error);
    }
  };

  const addTeamMember = () => {
    setProfile((prev) => ({
      ...prev,
      team: [...prev.team, { name: "", title: "", image: null }],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Adding form data fields
    formData.append("title_en", profile.title_en);
    formData.append("title_ar", profile.title_ar);
    formData.append("description_en", profile.description_en);
    formData.append("description_ar", profile.description_ar);
    formData.append("feature1_en", profile.feature1_en);
    formData.append("feature1_ar", profile.feature1_ar);
    formData.append("feature2_en", profile.feature2_en);
    formData.append("feature2_ar", profile.feature2_ar);
    formData.append("vision_en", profile.vision_en);
    formData.append("vision_ar", profile.vision_ar);
    formData.append("mission_en", profile.mission_en);
    formData.append("mission_ar", profile.mission_ar);

    try {
      const response = await axios.put(
        "https://ecofuture-backend.geek-house.dev/en/about/update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      console.log(response.data);
      setOpen(true);

      // Handle successful submission
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      // Handle errors
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ecofuture-backend.geek-house.dev/en/about"
        );
        const data = response.data;

        setProfile({
          ...profile, // maintain other state values
          title_en: data.title_en,
          title_ar: data.title_ar, // assuming the title field
          description_en: data.description_en,
          description_ar: data.description_ar,
          vision_en: data.vision_en,
          vision_ar: data.vision_ar,
          mission_en: data.mission_en,
          mission_ar: data.mission_ar,
          vision_photo: data.vision_photo,
          mission_photo: data.mission_photo,
          feature1_ar: data.feature1_ar,
          feature1_en: data.feature1_en,
          feature2_ar: data.feature2_ar,
          feature2_en: data.feature2_en,
        });
      } catch (error) {
        console.error("There was an error fetching the data:", error);
      }
    };

    fetchData();
    fetchTeam();
  }, []);
  return (
    <Box sx={{ mb: 3 }} mx="auto" width={"100%"}>
      <Typography component={"h1"} sx={{ ml: 1, mb: 3 }} variant="h3">
        About ECO Future
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Title & Description Card */}
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h5">Title & Description</Typography>
            <TextField
              label="Title (English)"
              name="title_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.title_en}
            />
            <TextField
              label="Title (Arabic)"
              name="title_ar"
              dir="rtl"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.title_ar}
            />
            <TextField
              label="Description (English)"
              name="description_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={profile.description_en}
            />
            <TextField
              label="Description (Arabic)"
              name="description_ar"
              dir="rtl"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={profile.description_ar}
            />
            <TextField
              label="feature 1 (Arabic)"
              name="feature1_ar"
              dir="rtl"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.feature1_ar}
            />
            <TextField
              label="feature 1 (English)"
              name="feature1_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.feature1_en}
            />
            <TextField
              label="feature 2 (Arabic)"
              name="feature2_ar"
              dir="rtl"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.feature2_ar}
            />
            <TextField
              label="feature 2 (English)"
              name="feature2_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={profile.feature2_en}
            />
          </CardContent>
        </Card>

        {/* Vision Card */}
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h5">Vision</Typography>
            <TextField
              label="Vision (English)"
              name="vision_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={profile.vision_en}
            />
            <TextField
              label="Vision (Arabic)"
              name="vision_ar"
              dir="rtl"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={profile.vision_ar}
            />{" "}
            <Box
              mt={2}
              mb={2}
              border="1px dotted"
              p={1}
              display="flex"
              alignItems="center"
            >
              <input
                style={{ display: "none" }}
                id="vision-image-upload"
                type="file"
                onChange={(e) => handleImageChange(e, "vision_photo")}
              />
              <label htmlFor="vision-image-upload">
                <Button component="span">Upload Image</Button>
              </label>
              {profile.vision_photo && (
                <>
                  <img
                    src={
                      typeof profile.vision_photo !== "string"
                        ? URL.createObjectURL(profile.vision_photo)
                        : profile.vision_photo
                    }
                    alt="Vision Preview"
                    width="100"
                  />
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleImageChange(
                        { target: { files: [null] } },
                        "vision_photo"
                      )
                    }
                  >
                    <Icon icon="material-symbols:delete-outline" />
                  </IconButton>
                </>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Mission Card */}
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h5">Mission</Typography>
            <TextField
              label="Mission (English)"
              name="mission_en"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={profile.mission_en}
            />
            <TextField
              label="Mission (Arabic)"
              name="mission_ar"
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              dir="rtl"
              rows={4}
              value={profile.mission_ar}
            />{" "}
            <Box
              mt={2}
              mb={2}
              border="1px dotted"
              p={1}
              display="flex"
              alignItems="center"
            >
              <input
                style={{ display: "none" }}
                id="mission-image-upload"
                type="file"
                onChange={(e) => handleImageChange(e, "mission_photo")}
              />
              <label htmlFor="mission-image-upload">
                <Button component="span">Upload Image</Button>
              </label>
              {profile.mission_photo && (
                <>
                  <img
                    src={
                      typeof profile.mission_photo !== "string"
                        ? URL.createObjectURL(profile.mission_photo)
                        : profile.mission_photo
                    }
                    alt="mission Preview"
                    width="100"
                  />
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleImageChange(
                        { target: { files: [null] } },
                        "mission_photo"
                      )
                    }
                  >
                    <Icon icon="material-symbols:delete-outline" />
                  </IconButton>
                </>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Team Card */}
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h5">Team</Typography>

            {team.map((member, index) => (
              <Card key={index} sx={{ mt: 2 }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">
                      Team Member {index + 1}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        const newTeam = [...team];
                        newTeam.splice(index, 1);
                        setTeam((prev) => ({ ...prev, team: newTeam }));
                      }}
                    >
                      <Icon icon="material-symbols:delete-outline" />
                    </IconButton>
                  </Box>

                  <TextField
                    placeholder="Name"
                    fullWidth
                    margin="normal"
                    value={member.full_name}
                  />
                  <TextField
                    placeholder="Title"
                    fullWidth
                    margin="normal"
                    value={member.job_title}
                  />

                  <Box
                    mt={2}
                    mb={2}
                    border="1px dotted"
                    p={1}
                    display="flex"
                    alignItems="center"
                  >
                    <input
                      style={{ display: "none" }}
                      id={`team-member-${index}-image-upload`}
                      type="file"
                      onChange={(e) =>
                        handleImageChange(e, `team[${index}].image`)
                      }
                    />
                    <label htmlFor={`team-member-${index}-image-upload`}>
                      <Button component="span">Upload Image</Button>
                    </label>
                    {member.image && (
                      <>
                        <img
                          src={URL.createObjectURL(member.image)}
                          alt="Team Member Preview"
                          width="100"
                        />
                        <IconButton
                          edge="end"
                          onClick={() =>
                            handleImageChange(
                              { target: { files: [null] } },
                              `team[${index}].image`
                            )
                          }
                        >
                          <Icon icon="material-symbols:delete-outline" />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            ))}

            <Button onClick={addTeamMember} sx={{ mt: 2 }}>
              Add Team Member
            </Button>
          </CardContent>
        </Card>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          mt={2}
          sx={{ mt: 3 }}
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully submitted!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompanyProfilePage;
