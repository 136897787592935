import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  CardMedia,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const ServicesPage = () => {
  //get all services from the backend
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          "https://ecofuture-backend.geek-house.dev/en/services"
        );
        const data = await response.json();
        setServices(data);
        console.log(data);
      } catch (error) {
        console.error("Failed to fetch services: ", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"justify-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="h4" sx={{ mr: "auto" }}>
          Services
        </Typography>
        <Link to="/dashboard/services/add">
          <Button variant="contained" size="large">
            Add Service{" "}
          </Button>
        </Link>
      </Grid>

      {services?.map((service) => (
        <Grid item md={6}>
          <Card key={service.id} sx={{ mb: 3 }}>
            <img
              component="img"
              height="400"
              width={"100%"}
              src={service.photo_1}
              alt="service"
            />
            <Link
              to={`/dashboard/services/${service.id}`}
              style={{ textDecoration: "none" }}
            >
              <CardActionArea>
                <CardContent>
                  <Typography variant="h5">{service.title_en}</Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesPage;
