import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { API_BASE_URL } from "../../constants/api";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Iconify from "src/components/iconify";
import axios from "axios";

const UpdateProductPage = () => {
  const [product, setProduct] = useState({
    title_ar: "",
    title_en: "",
    description_en: "",
    description_ar: "",
    images: [],
    category: "",
    status: true,
    in_stock: 0,
    tags: [],
    file: null,
  });

  const [status, setStatus] = useState(false);
  const [featuredImageId, setFeaturedImageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inStock, setInStock] = useState(false);
  const [images, setImages] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [editorStateEn, setEditorStateEn] = useState(EditorState.createEmpty());
  const [editorStateAr, setEditorStateAr] = useState(EditorState.createEmpty());
  const [categories, setCategories] = useState([]);

  const { productId } = useParams();

  const TAGS_DATA = [
    { id: "home", name: "Home", icon: "iconamoon:home" },
    { id: "business", name: "Business", icon: "ion:business" },
    { id: "solar", name: "Solar", icon: "fa6-solid:solar-panel" },
    { id: "battery", name: "Battery", icon: "majesticons:battery" },
  ];

  const handleChange = (event) => {
    console.log("name", event.target.value);
    const name = event.target.name;
    setProduct({
      ...product,
      [name]: event.target.value,
    });
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://ecofuture-backend.geek-house.dev/en/productcategories"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
      console.log(data);
    } catch (error) {
      console.error("Fetching categories failed: ", error);
    }
  };


  const handlePdfFileChange = (event) => {
    const selectedPdfFile = event.target.files[0];
    setPdfFile(selectedPdfFile);

    // Create an object URL for the PDF
    const pdfURL = URL.createObjectURL(selectedPdfFile);
    setPdfUrl(pdfURL);
  };

  const clearPdf = () => {
    // Clear the PDF from state and revoke the object URL
    setPdfFile(null);
    URL.revokeObjectURL(pdfUrl);
    setPdfUrl(null);
  };

  const handslestatusChange = (event) => {
    console.log("status", event.target.checked);
    setStatus(event.target.checked);
  };
  const handleInStockChange = (event) => {
    console.log("inStock", event.target.checked);
    setInStock(event.target.checked);
  };

  const handleEditorStateChangeEn = (editorState) => {
    setEditorStateEn(editorState);
    const html = stateToHTML(editorState.getCurrentContent());
    setProduct({
      ...product,
      description_en: html,
    });
  };

  const handleEditorStateChangeAr = (editorState) => {
    setEditorStateAr(editorState);
    const html = stateToHTML(editorState.getCurrentContent());
    setProduct({
      ...product,
      description_ar: html,
    });
  };

  useEffect(() => {
    if (productId) {
      // Make sure ID is available
      const fetchProduct = async () => {
        try {
          const res = await fetch(`${API_BASE_URL}/product/${productId}`);
          if (res.ok) {
            const data = await res.json();
            setProduct(data.product);
            if (data.product.status !== "") {
              setStatus(true);
            } else {
              setStatus(false);
            }

            if (data.product.in_stock === 1) {
              setInStock(true);
            }
            if (data.product.description_en) {
              const contentState = stateFromHTML(data.product.description_en);
              setEditorStateEn(EditorState.createWithContent(contentState));
            }
            if (data.product.description_ar) {
              const contentState = stateFromHTML(data.product.description_ar);
              setEditorStateAr(EditorState.createWithContent(contentState));
            }

            setImages(data.product.photos);
          } else {
            setError(`Failed to fetch product. HTTP Status: ${res.status}`);
          }
        } catch (e) {
          setError(`An error occurred: ${e.message}`);
        } finally {
          setLoading(false);
        }
      };

      fetchProduct();
    }
  }, [productId]);

  const Tag = ({ tag, isSelected, onTagClick }) => (
    <Card
      variant="outlined"
      align="center"
      style={{
        cursor: "pointer",
        backgroundColor: isSelected ? "#089537" : "white",
      }}
      onClick={() => onTagClick(tag.id)}
    >
      <CardContent>
        <Icon icon={tag.icon} color={isSelected ? "white" : "black"} />
        <Typography color={isSelected ? "white" : "black"}>
          {tag.name}
        </Typography>
      </CardContent>
    </Card>
  );

  const handleTagClick = (tagId) => {
    setProduct((prev) => ({
      ...prev,
      tags: prev.tags.includes(tagId)
        ? prev.tags.filter((t) => t !== tagId)
        : [...prev.tags, tagId],
    }));
  };

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files).map((file) => ({
      file,
      id: Math.random().toString(),
      isNew: true, // New property
    }));

    setImages((prev) => [...prev, ...newImages]);

    if (!featuredImageId && newImages.length) {
      console.log("Featured Image ID:", newImages[0].id);
      setFeaturedImageId(newImages[0].id);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      console.log("Deleted image ID:", imageId);
      await axios.delete(`${API_BASE_URL}/productphoto/delete/${imageId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      setImages((prev) => prev.filter((image) => image.id !== imageId));

      if (imageId === featuredImageId) {
        console.log("Deleted image was the featured image.");
        setFeaturedImageId(null);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("category_id", product.category.id);
    formData.append("title_en", product.title_en);
    formData.append("title_ar", product.title_ar);
    formData.append("in_stock", inStock ? 1 : 0); // assuming in_stock is a number
    formData.append("status", status ? "Special Price" : ""); // assuming status is a string
    const htmlDescriptionEn = stateToHTML(editorStateEn.getCurrentContent());
    formData.append("description_en", htmlDescriptionEn);

    const htmlDescriptionAr = stateToHTML(editorStateAr.getCurrentContent());
    formData.append("description_ar", htmlDescriptionAr);
    // Loop over the tags to add them to form data
    TAGS_DATA.forEach((tag) => {
      formData.append(tag.id, product.tags.includes(tag.id) ? 1 : 0);
    });

    formData.append("pdf", pdfFile);


    // Add photos to form data
    images.forEach((image, index) => {
      if (image.isNew) {
        // Only add new images
        formData.append(`photos`, image.file);
      }
    });
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const response = await axios.put(
      `https://ecofuture-backend.geek-house.dev/en/product/update/${productId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      minHeight="100vh"
      bgcolor="background.default"
      width={"100%"}
    >
      <Container component="main" maxWidth="xl">
        <Card>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Update Product
            </Typography>
            <form onSubmit={handleSubmit} >
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={product.title_en}
                  required
                  fullWidth
                  placeholder="Product Name"
                  name="title_en"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={product.title_ar}
                  required
                  fullWidth
                  label="Arabic Product Name"
                  name="title_ar"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    height: "120px",
                    border: "2px dotted #ccc",
                    borderRadius: "4px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography>Click to select files</Typography>
                  </Box>
                </label>
              </Box>
              <Box mb={2}>
                <Grid container spacing={3}>
                  {images?.map((image, index) => {
                    const isNew = image.isNew;

                    const isFile = typeof image !== "string";
                    const url = isNew
                      ? URL.createObjectURL(image.file)
                      : image.photo;

                    return (
                      <Grid item xs={4} key={index}>
                        <img
                          src={url}
                          alt="uploaded preview"
                          style={{ width: 200, height: "auto" }}
                        />
                        {featuredImageId === (isFile ? image.id : image) && (
                          <Icon
                            icon="fa-solid:star"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          />
                        )}
                        <IconButton
                          edge="end"
                          onClick={() => handleDeleteImage(image.id)}
                        >
                          <Icon icon="typcn:delete" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            console.log("isFile:", isFile);
                            console.log("Image ID:", image.id);
                            setFeaturedImageId(isFile ? image.id : image);
                            console.log("setFeaturedImageId",setFeaturedImageId);
                          }}
                        >
                          <Icon
                            icon="fa-solid:star"
                            color={
                              featuredImageId === (isFile ? image.id : image)
                                ? "gold"
                                : "gray"
                            }
                          />
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth variant="outlined" margin="normal" >
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="category"
                    value={product.category}
                    onChange={handleChange}
                    label="Category"
                    required
                  >
                    {/* Populate this with your categories */}
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category} >
                        {category.title_en}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={status}
                        checked={status}
                        onChange={handslestatusChange}
                        name="status"
                      />
                    }
                    label="Special Price"
                  />
                </FormGroup>
              </Box>

              <Box
                mb={2}
                sx={{
                  border: "1px solid grey",
                  borderRadius: "4px",
                  padding: "16px",
                  marginBottom: "20px",
                }}
              >
                <Editor
                  variant="outlined"
                  margin="normal"
                  defaultValue={product.description_en}
                  placeholder="Product Description"
                  name="description"
                  onEditorStateChange={handleEditorStateChangeEn}
                  editorState={editorStateEn}
                />
              </Box>
              <Box
                mb={2}
                sx={{
                  border: "1px solid grey",
                  borderRadius: "4px",
                  padding: "16px",
                  marginBottom: "20px",
                }}
              >
                <Editor
                  variant="outlined"
                  margin="normal"
                  defaultValue={product.description_ar}
                  dir="rtl"
                  placeholder="Arabic Product Description"
                  rows={4}
                  name="arabicDescription"
                  editorState={editorStateAr}
                  onEditorStateChange={handleEditorStateChangeAr}
                />
              </Box>
              <Box mb={2}>
                <FormGroup>
                  <FormControlLabel
                    sx={{ fontSize: "1.5rem" }}
                    control={
                      <Checkbox
                        checked={inStock}
                        value={inStock}
                        onChange={handleInStockChange}
                        name="inStock"
                      />
                    }
                    label="In Stock"
                  />
                </FormGroup>
              </Box>
              <Box mb={2} display="flex" justifyContent="start" gap={4}>
                {TAGS_DATA.map((tag) => (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    isSelected={product.tags.includes(tag.id) ? 1 : 0}
                    onTagClick={handleTagClick}
                  />
                ))}
              </Box>
              <Box mb={2}>
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    height: "50px",
                    border: "2px dotted #ccc",
                    borderRadius: "4px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <input
                    accept="application/pdf"
                    type="file"
                    onChange={handlePdfFileChange}
                    style={{ display: "none" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography>Click to select a PDF file</Typography>
                  </Box>
                </label>
              </Box>
              {pdfFile && (
                <Typography
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  <Iconify
                    icon={"teenyicons:pdf-solid"}
                    sx={{ color: "red", width: "40px", height: "100%" }}
                  />
                  <Typography>{pdfFile.name}</Typography>
                  <IconButton onClick={() => setPdfFile(null)}>
                    <Iconify icon={"eva:trash-2-outline"} />
                  </IconButton>
                </Typography>
              )}

              {/* preview the old file */}

              <Typography
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                <Iconify
                  icon={"teenyicons:pdf-solid"}
                  sx={{ color: "red", width: "40px", height: "100%" }}
                />
                <Typography component={"a"} href={product.pdf} target="_blank">
                  Preview current one
                </Typography>
                <IconButton onClick={clearPdf}>
                  <Iconify icon={"eva:trash-2-outline"} />
                </IconButton>
              </Typography>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default UpdateProductPage;
