import PropTypes from "prop-types";
// @mui
import { Box, Card, Typography, Stack, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";

import Iconify from "src/components/iconify";
// utils
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, deleteProduct }) {
  const { title_en, photos, id } = product;
  const cover = photos[0]?.photo;

  return (
    <Card>
      <CardMedia
        component="img"
        image={cover}
        title={title_en}
        sx={{
          height: 500,
          objectFit: "cover",
          width: "100%",
        }}
      />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography
          variant="subtitle1"
          noWrap
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Link
            color="white"
            underline="hover"
            to={`/dashboard/products/${id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textDecoration: "none" }}
            >
              {title_en}
            </Typography>
          </Link>
          <Typography variant="body2" display={"flex"}>
            <Iconify
              icon="eva:trash-2-fill"
              color="red"
              width={30}
              onClick={() => deleteProduct(id)}
              sx={{ cursor: "pointer" }}
            />
          </Typography>
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: "text.disabled",
                textDecoration: "line-through",
              }}
            ></Typography>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
