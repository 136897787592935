import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const ContactInfoPage = () => {
  const [contactInfo, setContactInfo] = useState({
    email_1: "",
    email_2: "",
    phone: "",
    address: "",
    facebook: "",
    telegram: "",
    twitter: "",
    instagram: "",
    whatsappChat: "",
    linkedin: "",
  });
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authToken = localStorage.getItem("auth_token");

    const formData = new URLSearchParams();
    formData.append("email_1", contactInfo.email_1);
    formData.append("email_2", contactInfo.email_2);
    formData.append("phone_number", contactInfo.phone);
    formData.append("address", contactInfo.address);
    formData.append("facebook", contactInfo.facebook);
    formData.append("telegram", contactInfo.telegram);
    formData.append("twitter", contactInfo.twitter);
    formData.append("instagram", contactInfo.instagram);
    formData.append("linkedin", contactInfo.linkedin);
    formData.append("whatsapp", contactInfo.whatsappChat);

    try {
      const response = await axios.put(
        "https://ecofuture-backend.geek-house.dev/en/contactinfo/update",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Info updated successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setLoading(false);
      } else {
        setSnackbarMessage(
          `Failed to update contact info: ${response.data.message}`
        );
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("There was an error updating the contact info:", error);
      setSnackbarMessage(`Network error: ${error.message}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ecofuture-backend.geek-house.dev/en/contactinfo"
        );
        const data = response.data;
        setContactInfo({
          email_1: data.email_1,
          email_2: data.email_2,
          phone: data.phone_number,
          address: data.address,
          facebook: data.facebook,
          telegram: data.telegram,
          twitter: data.twitter,
          instagram: data.instagram,
          linkedin: data.linkedin,
          whatsappChat: data.whatsapp,
        });
      } catch (error) {
        console.error("There was an error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box p={3}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h5">Contact Information</Typography>
            <TextField
              label="Email 1"
              name="email_1"
              value={contactInfo.email_1}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Email 2"
              name="email_2"
              value={contactInfo.email_2}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              name="phone"
              value={contactInfo.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              name="address"
              value={contactInfo.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </CardContent>
        </Card>

        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h5">Social Media</Typography>
            <TextField
              label="Facebook"
              name="facebook"
              value={contactInfo.facebook}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Telegram"
              name="telegram"
              value={contactInfo.telegram}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Twitter"
              name="twitter"
              value={contactInfo.twitter}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Instagram"
              name="instagram"
              value={contactInfo.instagram}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </CardContent>
        </Card>

        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h5">WhatsApp Chat</Typography>
            <TextField
              label="WhatsApp Number"
              name="whatsappChat"
              placeholder="e.g. 00123456789"
              value={contactInfo.whatsappChat}
              onChange={handleChange}
              fullWidth
              margin="normal"
              helperText="This number will be used in the chat popup message."
            />
          </CardContent>
        </Card>

        <Button type="submit" variant="contained" color="primary">
          {loading ? <CircularProgress /> : "Save changes"}
        </Button>
      </form>
    </Box>
  );
};

export default ContactInfoPage;
