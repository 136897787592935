import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loginAPI = async (username, password) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://ecofuture-backend.geek-house.dev/en/login",
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      setError(true);
      console.error("Error during API call", error);
      return null;
    }
  };
  const handleLogin = async () => {
    const username = document.querySelector('input[name="email"]').value;
    const password = document.querySelector('input[name="password"]').value;
    const response = await loginAPI(username, password);

    if (response && response.token) {
      localStorage.setItem("auth_token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
      navigate("/dashboard", { replace: true });
    } else {
      console.error("Login failed");
      // Handle login failure accordingly
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Alert severity="error" sx={{ display: error ? "" : "none" }}>
          Invalid email or password
        </Alert>
      </Stack>

      <LoadingButton
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleLogin}
      >
        {loading ? "Loading..." : "Login"}
      </LoadingButton>
    </>
  );
}
