import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";

import { useParams } from "react-router-dom";
import axios from "axios";

const ServicePage = () => {
  const { serviceId } = useParams();

  const [serviceData, setServiceData] = useState({
    category_id: null,
    title_en: "",
    title_ar: "",
    description_1_en: "",
    description_1_ar: "",
    description_2_en: "",
    description_2_ar: "",
    photo_1: null,
    photo_2: null,
    features: [],
  });
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    titleAr: "",
    titleEn: "",
    descriptionAr: "",
    descriptionEn: "",
    image: null,
  });

  const [serviceImage, setServiceImage] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setServiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBulletPointChange = (index, lang, value) => {
    const updatedFeatures = [...serviceData.features];
    updatedFeatures[index][lang] = value;
    setServiceData((prevData) => ({
      ...prevData,
      features: updatedFeatures,
    }));
  };

  const handleServiceImageChange = (e) => {
    const file = e.target.files[0];
    setServiceImage(file);
  };

  const updateService = async () => {
    // Create FormData object to hold the API request payload
    const formData = new FormData();
    const authToken = localStorage.getItem("auth_token");

    // Populate the FormData object with service data
    formData.append("category_id", serviceData.category_id);
    formData.append("title_en", serviceData.title_en);
    formData.append("title_ar", serviceData.title_ar);
    formData.append("description_1_en", serviceData.description_1_en);
    formData.append("description_1_ar", serviceData.description_1_ar);
    formData.append("description_2_en", serviceData.description_2_en);
    formData.append("description_2_ar", serviceData.description_2_ar);

    // Populate feature fields dynamically based on the features array
    serviceData.features.forEach((feature, index) => {
      formData.append(`feature${index + 1}_en`, feature.en);
      formData.append(`feature${index + 1}_ar`, feature.ar);
    });

    try {
      const response = await axios.put(
        `https://ecofuture-backend.geek-house.dev/en/service/update/${serviceId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`, // Add the auth token to the request header
          },
        }
      );

      if (response.data.success) {
        console.log("Service updated successfully");
      } else {
        console.error("Error updating service:", response.data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append("file", serviceImage);

    try {
      const response = await fetch(
        "https://ecofuture-backend.geek-house.dev/en/servicephoto/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.success && data.imageURL) {
        // Call updateService with the imageURL returned after a successful upload
        updateService(data.imageURL);
      } else {
        console.error("Error uploading image:", data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const removeServiceImage = () => {
    setServiceImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateService();
  };

  useEffect(() => {
    const fetchService = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://ecofuture-backend.geek-house.dev/en/service/${serviceId}`
        );
        const fetchedData = response.data;

        // Ensure features array has at least one empty object
        if (!fetchedData.features || fetchedData.features.length === 0) {
          fetchedData.features = [
            { en: "", ar: "" },
            { en: "", ar: "" },
            { en: "", ar: "" },
            { en: "", ar: "" },
            { en: "", ar: "" },
          ];
        }
        setServiceData(response.data);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch products: ", error);
        setLoading(false);
      }
    };

    fetchService();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography mb={3} variant="h5">
            Service Info
          </Typography>
          <Box
            mt={2}
            mb={2}
            border="1px dotted"
            p={1}
            display="flex"
            alignItems="center"
          >
            <input
              style={{ display: "none" }}
              id="service-image-upload"
              type="file"
              onChange={handleServiceImageChange}
            />
            <label htmlFor="service-image-upload">
              <Button component="span">Upload Image 1</Button>
            </label>

            <>
              <img
                src={
                  typeof serviceData.photo_1 === "string"
                    ? serviceData.photo_1
                    : serviceData.photo_1
                    ? URL.createObjectURL(serviceData.photo_1)
                    : null
                }
                alt="service"
                style={{ width: "100px", height: "100px" }}
              />
              <IconButton edge="end" onClick={removeServiceImage}>
                <Icon icon="material-symbols:delete-outline" />
              </IconButton>
            </>
          </Box>
          <Box
            mt={2}
            mb={2}
            border="1px dotted"
            p={1}
            display="flex"
            alignItems="center"
          >
            <input
              style={{ display: "none" }}
              id="service-image-upload"
              type="file"
              onChange={handleServiceImageChange}
            />
            <label htmlFor="service-image-upload">
              <Button component="span">Upload Image 2</Button>
            </label>

            <>
              <img
                src={
                  typeof serviceData.photo_2 === "string"
                    ? serviceData.photo_2
                    : serviceData.photo_2
                    ? URL.createObjectURL(serviceData.photo_1)
                    : null
                }
                alt="service"
                style={{ width: "100px", height: "100px" }}
              />
              <IconButton edge="end" onClick={removeServiceImage}>
                <Icon icon="material-symbols:delete-outline" />
              </IconButton>
            </>
          </Box>

          <TextField
            fullWidth
            placeholder="Title (Arabic)"
            name="title_ar"
            dir="rtl"
            value={serviceData.title_ar || ""}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            placeholder="Title (English)"
            name="title_en"
            value={serviceData.title_en || ""}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Description (Arabic)"
            name="description_1_ar"
            dir="rtl"
            value={serviceData.description_1_ar || ""}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Description (English)"
            name="description_1_en"
            value={serviceData.description_1_en || ""}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            multiline
            rows={4}
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h5">Service Features</Typography>
          <Box mt={2}>
            {serviceData.features?.map((feature, index) => (
              <div key={index}>
                <TextField
                  key={`${index}_en`}
                  fullWidth
                  label={`Feature ${index + 1} (English)`}
                  name={`feature_en_${index}`}
                  value={feature.en || ""}
                  onChange={(e) =>
                    handleBulletPointChange(index, "en", e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  key={`${index}_ar`}
                  fullWidth
                  label={`Feature ${index + 1} (Arabic)`}
                  name={`feature_ar_${index}`}
                  dir="rtl"
                  value={feature.ar || ""}
                  onChange={(e) =>
                    handleBulletPointChange(index, "ar", e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
              </div>
            ))}
          </Box>
        </CardContent>
      </Card>

      <Button
        size="large"
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
      >
        Edit Service
      </Button>
    </form>
  );
};

export default ServicePage;
